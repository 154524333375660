import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <span className='text-header'>
         ThoughTrain
        </span>
        <span className='text-description'>
          A free for all learning platform.
        </span>
        <span className='text-footer'>
          # Coming Soon #
        </span>
      </header>
    </div>
  );
}

export default App;
